
.wrapper-products
    flex-flow row wrap
    background rgba(239,125,0,0.6)
    width 100%

    @media (min-width 992px)
        padding 15px

    .product-item
        padding 15px
        width 100%
        margin auto
        font-family zero-width

        a
            display block
            transition all 0.4s
            // box-shadow 0 0 4px 0 lightgray
            position relative
            background-color rgba(255, 255, 255, 0.39)


            // font-size 0

            img
                display block
                width 100%

            &:hover
                box-shadow 0 0 10px 2px darken(#f39733, 15)
                transform scale(1.02)

            &:after
                content " "
                position absolute
                top 0
                bottom 0
                width 100%
                height 100%
                background-color white
                opacity 0
                transition all 0.4s

            &:hover:after
                opacity 0.4

        @media (min-width 560px)
            width 50%

        @media (min-width 1600px)
            width 25%
