
.wrapper-login
    position fixed
    top 0
    bottom 0
    height 100%
    width 100%
    justify-content center
    align-items center
    align-content center

    .btn-home
        display inline-block
        text-decoration none
        position absolute
        top 0
        right 0
        color white
        transition all 200ms
        padding-right 20px
        padding-top 20px
        font-size 1.2rem

        &:hover
            color orange
            font-size 1.6rem


    form
        width 100%
        padding 50px

        @media (min-width: 400px)
            width 400px
