*,
*:before,
*:after
    box-sizing border-box

html
    height 100%
    // overflow-y scroll

    body
        min-height 100%
        /*background-color #f39733*/
        background url("http://clonebe.com/resources/production/img/fondo-2.jpg") no-repeat center center fixed
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-color rgba(0, 0, 0, 0) !important

        position relative

        .body-wrapper
            min-height 100vh

            main
                padding-top 50px
                min-height 100vh
                display flex
                padding-bottom 120px

                @media (max-width 560px)
                    padding-left 0 !important

                // @media (min-width 992px)
                //     padding-top 80px
