
.wrapper-user-data
    display flex
    width 100%
    background-color rgba(239,125,0,0.6)

    // @media (min-width: 1022px)
    //     background-color rgba(239,125,0,0.6)

    form
        margin auto
        padding 15px

        @media (min-width: 992px)
            width 992px

    // nav
    //     background-color gray
    //     color white
    //     width 350px

    //     li
    //         background-color darkgray
    //         padding 20px
    //         box-shadow inset 0 0 2px black

