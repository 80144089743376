
html body
    .wrapper-404
        display flex
        flex-flow column nowrap
        justify-content center !importnant
        align-items center
        width 100vw
        height 90vh
        position absolute
        left 50%
        transform translateX(-50%)
        background-color black

        #actual-404
            color red
            font-size 80vh
            font-weight bolder
