.wrapper-textos-legales
    margin 0px 0px 0px 13px
    padding 5px
    background rgba(239,125,0,0.6)
    
    @media (min-width 400px)
        padding 30px

    
    p,h1,h2,h3,h4,h5
        color #FFF
        line-height 20px
        
        @media (min-width 400px)
            line-height 26px
    
    h2
        margin 10px 10px 20px 10px
        text-align center
        font-size 20px
        line-height 22px
        
        
        @media (min-width 400px)
            font-size 25px
            line-height 27px
        
    h3
        margin 25px 10px 15px 10px
        text-align left
        font-size 20px
        font-weight bold
        
        @media (min-width 400px)
            font-size 20px
        
    h4
        margin 10px 10px 10px 10px
        text-align left
        font-weight bold
        font-size 18px
        
        @media (min-width 400px)
            font-size 20px
    
    h5
        margin 10px 10px 10px 10px
        text-align left
        font-weight bold
        text-decoration underline
    p
        margin 0px 10px 0px 10px
        text-align justify
        font-size 15px
        
        @media (min-width 400px)
            font-size 14px
        
    ul
        list-style-type square
        margin 10px 0px 10px 30px
        color #FFF
        
    li
        margin 0px 0px 15px 0px
        
    a
        color #575756
        text-decoration none
         
    a:hover
        color #999999
        
    .title
        color #e6e6e6
        margin 0px 10px 15px 10px
        text-align center
        font-size 15px
        
    table
        background rgba(0,0,0,0.4)
        padding 20px 20px 20px 20px
        margin 20px 10px 20px 10px