
.wrapper-purchase-callback
    display flex
    align-items center
    width 100%
    margin 0 15px

    @media (min-width 992px)
        margin 0

    .alert
        margin auto

    & > h3
        margin 20px 0

    #purchase-product-list
        width 100%
        margin 10px

        li.purchase-product-item
            overflow hidden
            position relative
            display flex
            flex-flow row wrap
            justify-content flex-start
            border-bottom 1px solid rgba(0, 0, 0, 0.2)
            background-color rgba(255, 255, 255, 0.85)

            &:last-of-type
                border-bottom 0

            div.always-visible
                width 100%
                display flex
                flex-flow row nowrap

                span
                    padding 10px

                span.dropdown-icon
                    display flex
                    justify-content center
                    align-items center
                    width 35px
                    padding 0
                    background-color rgba(243, 151, 51, 0.5)

                    div.arrow
                        margin auto
                        border-top 6px solid transparent
                        border-bottom 6px solid transparent
                        border-left 8px solid #944d00
                        transition all 500ms

                    div.arrow.rotate-arrow
                        transform rotate(90deg)

                span.title
                    flex 100 100 auto
                    white-space nowrap
                    overflow hidden
                    text-overflow ellipsis
                    font-weight bolder
                    background-color white

                span.price
                    background-color rgba(109, 211, 147, 0.5)
                    width 60px
                    white-space nowrap
                    overflow hidden
                    text-overflow ellipsis
                    text-align right

                    &:after
                        content "€"

            div.expanded
                width 100%
                display none

                figure
                    font-size 0
                    background-color rgba(243, 151, 51, 0.5)
                    text-align center


                    img
                        width 100%
                        max-width 400px

                ul
                    li
                        display flex
                        justify-content space-between
                        border-bottom 1px solid #ddd
                        padding 10px

                        &:first-of-type
                            border-top  1px solid #ddd

                        &:last-of-type
                            border-bottom 0

                        span:first-of-type
                            font-weight bolder
                            color #444

                        span:last-of-type
                            font-weight 200
                            color #888

    button
        text-transform uppercase

    button:last-of-type
        margin-bottom 15px


