header
    .nav-contents
        min-height 50px
        display flex
        flex-flow row nowrap
        justify-content flex-start
        align-items center
        padding-left 15px
        padding-right 15px

        @media (min-width 992px)
            padding 0

        .navbar-collapse
            padding 0

        & > a,
        & > form
            margin-right 15px

        form
            flex 2 1 auto

            div.input-group
                display flex
                flex 2 1 auto
                flex-flow row nowrap
                justify-content flex-end
                align-items center
                min-height 34px

                input
                    position absolute
                    top 0
                    right 0
                    width 0
                    z-index 2

                .input-group-btn
                    position absolute
                    top 0
                    right 0
                    z-index 3
                    display inline-block
                    width auto

        a.logo
            position absolute
            z-index 1
            top 0
            height 50px
            border-top 8px solid rgba(0, 0, 0, 0)
            border-bottom 8px solid rgba(0, 0, 0, 0)
            width 125.71052631578947px
            background url("../img/logo-clonebe.png") no-repeat center center
            background-size contain

        .dropdown
            .navbar-toggle
                margin 0
                float none

                @media (min-width 768px)
                    display inline-block !important

            .dropdown-menu
                left auto !important
                right 0
                top 40px
