
.wrapper-report
    display flex
    width 100%

    ul
        width 100%
        margin auto
        display flex
        flex-flow row wrap
        justify-content space-around

        li
            margin 15px


            a
                display flex
                width calc(150px + 10vw)
                height @width
                padding 0
                border 4px solid #ef7200
                background-color rgba(255, 255, 255, 0.8)
                border-radius 50%
                position relative
                transition all 600ms
                text-decoration none

                &:after
                    content "\e026"
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                    z-index 1
                    background-color #ef7200
                    border-radius 50%
                    display flex
                    justify-content center
                    align-items center
                    font-family 'Glyphicons Halflings'
                    font-size 140px
                    opacity 0
                    transition all 200ms
                    transform: scale(0)
                    color white

                &:hover,
                &:active
                    background-color rgba(255, 255, 255, 0.8)
                    border 4px solid white
                    background-position 0
                    transform scale(1.1)

                    &:after
                        opacity 1
                        transform: scale(1)

                div
                    white-space normal
                    line-height 3.6rem
                    font-size 24px
                    font-weight bold
                    width 100%
                    padding 30px
                    margin auto
                    color #ef7200
                    text-align center
                    text-transform capitalize

                    @media(max-width 700px)
                        font-size 18px

                    @media(max-width 400px)
                        font-size 16px

.animation-ripple
    animation ripple 1000ms


@keyframes ripple
    0%
        box-shadow 0 0 0 0 transparent

    5%
        box-shadow 0 0 0 0 rgba(239, 114, 0, 0.4)

    100%
        box-shadow 0 0 0 100vw rgba(239, 114, 0, 0)

