
hAreasSpacing = 15px

.wrapper-product-item

    width 100%
    padding 15px

    .alert
        margin auto

    .top-data
        width 100%
        display flex
        flex-flow row nowrap
        justify-content space-between
        align-items center
        margin-bottom hAreasSpacing
        margin-top 15px

    .product
        width 100%
        display flex
        flex-flow row nowrap
        justify-content space-between
        align-items center

        .product-viewport
            width 100%
            background-color rgba(239,125,0,0.6)
            flex 2 1 0

            figure,
            canvas
                width 100%

    .bottom-data
        width 100%
        margin-top hAreasSpacing
        margin-bottom 20px
        display flex
        flex-flow row nowrap
        justify-content space-between
        align-items flex-start


        & > div.price
            position relative

            & > .input-group
                position absolute
                top 0
                left 0

    @media (min-width 992px)
        padding 0

// lateral sidebars
body
    #options-right

        form
            display flex
            flex-flow row nowrap
            justify-content space-between
            align-items center

            input[type=range]
                flex grow 101 100 auto

            output
                width 15%
                background-color white
                border-radius 2px
                box-shadow inset 0 0 3px gray
                text-align right
                padding 5px
                font-weight bolder
                flex 100 1 80px
                margin-right 3px

                &:after
                    content: 'mm'
                    font-weight bolder

        ul li a
            padding-left 3px

            input
                margin-right 15px


