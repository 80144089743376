.navbar-toggle,
.btn-default,
.btn,
.navbar-default,
.navbar-default .navbar-toggle,
.input-group-addon,
.form-control
        border-radius 0px
        background-image none
        color #ef7d00
        background-color #fff
        border-color #e6e6e6
        box-shadow none

.navbar-default .navbar-toggle .icon-bar
        background-color: #ef7d00

.default-container-width
    width 100vw

    @media (min-width 992px)
        width 83.33333333%
        position relative
        left 50%
        transform translateX(-50%)

    @media (min-width 1200px)
        width 66.66666666%

.fix-form-group
    padding 0 15px


.button,
.btn
    text-transform capitalize

    span
        text-transform capitalize

.ajax-load-wrapper
    display flex
    width 100%
    justify-content center
    align-items center
