
.wrapper-upload-product
    display flex
    width 100%
    background-color rgba(239,125,0,0.6)

    .alert
        margin auto

    form
        margin auto
        max-width calc(100% - 30px) !important

        @media (min-width: 992px)
            width 992px

        fieldset
            border-radius 6px
            padding 15px
            margin 15px 0 40px
            background-color rgba(20, 20, 20, 0.2)

            .progress-bar
                height 40px
                width 0
                background-color blue
                display none

            .drop-zone
                display flex
                border 6px dashed rgba(239, 125, 0, 0.8)
                min-height 25vh
                background-color rgba(255,255,255, 0.6)
                display none

                .wrapper
                    margin auto
                    color rgba(239, 125, 0, 0.8)
                    text-shadow 0 0 8px rgba(255,255,255, 0.75)
                    text-align center
                    font-size 3vh
                    font-weight bold

            .progress-bar
                display none

            legend
                width inherit
                font-size 18px
                background-color rgba(239, 125, 0, 0.8)
                box-shadow 2px 2px 6px 0 rgba(139, 25, 0, 0.6)
                color white
                border-radius 4px
                padding 9px 15px

            ul li
                padding 10px

                &:hover
                    background-color #eee




