
.wrapper-user-activation
    display flex
    flex-flow column nowrap
    justify-content center
    align-items center
    width 100%

    .alert
        display inline-block

    form
        .alert
            display block

        padding 15px

        @media (min-width: 992px)
            width 992px
