
.wrapper-user-area-pricing
    display flex
    width 100%
    background-color rgba(239,125,0,0.6)

    .alert
        margin auto

    & > div.table-area
        margin 10px
        width calc(100% - 10px)

        .table-group-control
            display flex
            justify-content flex-end

            #table-opts,
            #table-opts-button // jQuery UI name for it
                margin-right auto

            button
                margin-left 10px

        .table-group
            display flex
            justify-content center
            align-items flex-start
            flex-flow row wrap
            padding-top 20px

            .dataTables_scrollBody
                height 400px !important

            // fix double header, used to scroll headings horizontally too
            .dataTables_scrollBody thead
                height 0 !important
                font-size 0 !important
                line-height 0 !important
                visibility hidden !important
                opacity 0 !important

            .dataTables_scrollBody
                background-color #f2f2f2

            .table-instance
                font-family 'Helvetica Neue', Helvetica, Arial, sans-serif
                width 290px
                position relative
                box-shadow 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)
                transition all 300ms
                margin-bottom 15px

                &:hover
                    box-shadow 0 5px 11px 0 rgba(0, 0, 0, 0.16), 0 4px 15px 0 rgba(0, 0, 0, 0.12)

                @media(min-width 500px)
                    margin-right 50px
                    margin-bottom 40px

                &:hover > .remove-material-btn
                    display block

                & > .remove-material-btn
                    color white
                    background-color black
                    border 3px solid white
                    width 60px
                    padding 0
                    font-size (@width / 2)
                    height @width
                    line-height @height
                    border-radius (@width / 2)
                    position absolute
                    top -(@width / 2)
                    left -(@width / 2)
                    transform: scale(0.45)
                    z-index 21302
                    display none
                    transition all 200ms

                    &:hover
                        text-shadow 0 0 10px white

                .table-controls
                    display flex
                    justify-content flex-end
                    color white
                    background-color #f39733
                    border-top 0
                    padding 5px

                    button
                        background-color rgba(0,0,0,0)
                        color white
                        text-transform capitalize
                        border 0
                        padding 5px
                        margin 0 10px 0 0
                        outline 0
                        font-weight bold
                        display flex
                        font-family zero-width

                        span
                            font-size 20px
                            height @ont-size
                            width @font-size
                            vertical-align center
                            transition all 200ms

                        span:hover
                            text-shadow 0 0 10px white

                .dataTables_scrollHead
                    background-color white

                    .dataTables_scrollHeadInner
                        position relative
                        z-index 1

                .dataTables_scrollBody
                    border 0
                    border-image-width 0

                table.dataTable thead th,table.dataTable thead td,
                table.dataTable.no-footer
                    border 0
                    border-image-width 0

                table
                    background-color white
                    table-layout fixed

                    caption
                        padding 10px 0
                        text-align center
                        color white
                        background-color #f39733
                        border-bottom 0
                        position relative
                        font-size 20px

                        & + thead th:last-of-type
                            position relative

                        & + thead th:last-of-type:after
                                content " "
                                position absolute
                                top 0
                                left 0
                                width calc(100% + 50px)
                                height 100%
                                background-color rgb(238, 198, 155)
                                z-index -1

                        &:before
                            content " "
                            position absolute
                            top 0
                            left 0
                            width calc(100% + 50px)
                            height 100%
                            background-color #f39733
                            z-index -1

                    thead,
                    tfoot
                        background-color lighten(#f39733, 30)
                        color #575756
                        text-transform capitalize

                    th,
                    td
                        text-align center

                    th:first-of-type,
                    td:first-of-type
                        width 10px
                        text-align center

                    th:nth-of-type(odd)
                        background-color rgba(darken(#ef7d00, 10), .2)

                    td:nth-of-type(odd)
                        background-color rgba(lighten(#ef7d00, 50), .5)

                    th:nth-of-type(even)
                        background-color rgba(darken(#ef7d00, 30), .2)

                    td:nth-of-type(even)
                        background-color rgba(lighten(#ef7d00, 40), .5)

                    tbody

                        tr:nth-of-type(odd)
                            background-color lighten(#ef7d00, 50)

                        tr:nth-of-type(even)
                            background-color lighten(#ef7d00, 40)

                        td
                            input:not([type=checkbox])
                                margin 0
                                padding 0 0 0 10px
                                border 0
                                outline 0
                                width 100%
                                height 100%
                                background-color rgba(0, 0, 0, 0)


