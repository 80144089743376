footer
    position absolute
    bottom 0
    width 100%
    background-color #575756

    .footer-contents-wrapper
        background-color #575756
        color white
        position relative
        font-size 12px
        padding 0px 0px 0px 0px
        text-align center
        display flex
        flex-flow row nowrap
        justify-content space-between
        align-items center
        padding 15px

        .left-area

            & > *
                font-size 15px

                @media (max-width 992px)
                    margin-right 0
                    display block
                    text-align left
                    margin-top 15px
                    margin-bottom 15px

                &:first-child
                    margin-top 0

                &:last-child
                    margin-right 0
                    margin-bottom 0

            @media (max-width 992px)
                padding 0


        .footer-logo
            width auto
            height 30px
            // padding 15px 0
            margin 0

        .footer-link
            color #FFF
            text-decoration none
            margin-left 30px

            &:first-child
                margin-left 0

        .footer-link:hover
            color #999999

        @media (min-width 992px)
            padding 15px 0
