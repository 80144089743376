*,
*:before,
*:after {
  box-sizing: border-box;
}
html {
  height: 100%;
}
html body {
  min-height: 100%;
/*background-color #f39733*/
  background: url("http://clonebe.com/resources/production/img/fondo-2.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: rgba(0,0,0,0) !important;
  position: relative;
}
html body .body-wrapper {
  min-height: 100vh;
}
html body .body-wrapper main {
  padding-top: 50px;
  min-height: 100vh;
  display: flex;
  padding-bottom: 120px;
}
@media (max-width: 560px) {
  html body .body-wrapper main {
    padding-left: 0 !important;
  }
}


/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
/**
 * Remove default margin.
 */
body {
  margin: 0;
  line-height: 1;
}
/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}
/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none;
}
/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background color from active links in IE 10.
 * 2. Improve readability of focused elements when they are also in
 *    an active/hover state.
 */
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}
/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold;
}
/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic;
}
/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/**
 * Address styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000;
}
/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}
/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
/* Embedded content
   ========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9/10.
 */
img {
  border: 0;
}
/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px;
}
/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0;
}
/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto;
}
/**
 * Address odd `em`-unit font size rendering in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
/* Forms
   ========================================================================== */
/**
 * Known limitation by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}
/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}
/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}
/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}
/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  padding: 0;
}
/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}
/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold;
}
/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}


html body .wrapper-404 {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center false;
  align-items: center;
  width: 100vw;
  height: 90vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
}
html body .wrapper-404 #actual-404 {
  color: #f00;
  font-size: 80vh;
  font-weight: bolder;
}

.wrapper-main-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.wrapper-login {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.wrapper-login .btn-home {
  display: inline-block;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  transition: all 200ms;
  padding-right: 20px;
  padding-top: 20px;
  font-size: 1.2rem;
}
.wrapper-login .btn-home:hover {
  color: #ffa500;
  font-size: 1.6rem;
}
.wrapper-login form {
  width: 100%;
  padding: 50px;
}
@media (min-width: 400px) {
  .wrapper-login form {
    width: 400px;
  }
}

.wrapper-product-item {
  width: 100%;
  padding: 15px;
}
.wrapper-product-item .alert {
  margin: auto;
}
.wrapper-product-item .top-data {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
}
.wrapper-product-item .product {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.wrapper-product-item .product .product-viewport {
  width: 100%;
  background-color: rgba(239,125,0,0.6);
  flex: 2 1 0;
}
.wrapper-product-item .product .product-viewport figure,
.wrapper-product-item .product .product-viewport canvas {
  width: 100%;
}
.wrapper-product-item .bottom-data {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.wrapper-product-item .bottom-data > div.price {
  position: relative;
}
.wrapper-product-item .bottom-data > div.price > .input-group {
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 992px) {
  .wrapper-product-item {
    padding: 0;
  }
}
body #options-right form {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
body #options-right form input[type=range] {
  flex: grow 101 100 auto;
}
body #options-right form output {
  width: 15%;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: inset 0 0 3px #808080;
  text-align: right;
  padding: 5px;
  font-weight: bolder;
  flex: 100 1 80px;
  margin-right: 3px;
}
body #options-right form output:after {
  content: 'mm';
  font-weight: bolder;
}
body #options-right ul li a {
  padding-left: 3px;
}
body #options-right ul li a input {
  margin-right: 15px;
}

.wrapper-products {
  flex-flow: row wrap;
  background: rgba(239,125,0,0.6);
  width: 100%;
}
@media (min-width: 992px) {
  .wrapper-products {
    padding: 15px;
  }
}
.wrapper-products .product-item {
  padding: 15px;
  width: 100%;
  margin: auto;
  font-family: zero-width;
}
.wrapper-products .product-item a {
  display: block;
  transition: all 0.4s;
  position: relative;
  background-color: rgba(255,255,255,0.39);
}
.wrapper-products .product-item a img {
  display: block;
  width: 100%;
}
.wrapper-products .product-item a:hover {
  box-shadow: 0 0 10px 2px #cd710c;
  transform: scale(1.02);
}
.wrapper-products .product-item a:after {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0;
  transition: all 0.4s;
}
.wrapper-products .product-item a:hover:after {
  opacity: 0.4;
}
@media (min-width: 560px) {
  .wrapper-products .product-item {
    width: 50%;
  }
}
@media (min-width: 1600px) {
  .wrapper-products .product-item {
    width: 25%;
  }
}

.wrapper-textos-legales {
  margin: 0px 0px 0px 13px;
  padding: 5px;
  background: rgba(239,125,0,0.6);
}
@media (min-width: 400px) {
  .wrapper-textos-legales {
    padding: 30px;
  }
}
.wrapper-textos-legales p,
.wrapper-textos-legales h1,
.wrapper-textos-legales h2,
.wrapper-textos-legales h3,
.wrapper-textos-legales h4,
.wrapper-textos-legales h5 {
  color: #fff;
  line-height: 20px;
}
@media (min-width: 400px) {
  .wrapper-textos-legales p,
  .wrapper-textos-legales h1,
  .wrapper-textos-legales h2,
  .wrapper-textos-legales h3,
  .wrapper-textos-legales h4,
  .wrapper-textos-legales h5 {
    line-height: 26px;
  }
}
.wrapper-textos-legales h2 {
  margin: 10px 10px 20px 10px;
  text-align: center;
  font-size: 20px;
  line-height: 22px;
}
@media (min-width: 400px) {
  .wrapper-textos-legales h2 {
    font-size: 25px;
    line-height: 27px;
  }
}
.wrapper-textos-legales h3 {
  margin: 25px 10px 15px 10px;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}
@media (min-width: 400px) {
  .wrapper-textos-legales h3 {
    font-size: 20px;
  }
}
.wrapper-textos-legales h4 {
  margin: 10px 10px 10px 10px;
  text-align: left;
  font-weight: bold;
  font-size: 18px;
}
@media (min-width: 400px) {
  .wrapper-textos-legales h4 {
    font-size: 20px;
  }
}
.wrapper-textos-legales h5 {
  margin: 10px 10px 10px 10px;
  text-align: left;
  font-weight: bold;
  text-decoration: underline;
}
.wrapper-textos-legales p {
  margin: 0px 10px 0px 10px;
  text-align: justify;
  font-size: 15px;
}
@media (min-width: 400px) {
  .wrapper-textos-legales p {
    font-size: 14px;
  }
}
.wrapper-textos-legales ul {
  list-style-type: square;
  margin: 10px 0px 10px 30px;
  color: #fff;
}
.wrapper-textos-legales li {
  margin: 0px 0px 15px 0px;
}
.wrapper-textos-legales a {
  color: #575756;
  text-decoration: none;
}
.wrapper-textos-legales a:hover {
  color: #999;
}
.wrapper-textos-legales .title {
  color: #e6e6e6;
  margin: 0px 10px 15px 10px;
  text-align: center;
  font-size: 15px;
}
.wrapper-textos-legales table {
  background: rgba(0,0,0,0.4);
  padding: 20px 20px 20px 20px;
  margin: 20px 10px 20px 10px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #575756;
}
footer .footer-contents-wrapper {
  background-color: #575756;
  color: #fff;
  position: relative;
  font-size: 12px;
  padding: 0px 0px 0px 0px;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
footer .footer-contents-wrapper .left-area > * {
  font-size: 15px;
}
@media (max-width: 992px) {
  footer .footer-contents-wrapper .left-area > * {
    margin-right: 0;
    display: block;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
footer .footer-contents-wrapper .left-area > *:first-child {
  margin-top: 0;
}
footer .footer-contents-wrapper .left-area > *:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
@media (max-width: 992px) {
  footer .footer-contents-wrapper .left-area {
    padding: 0;
  }
}
footer .footer-contents-wrapper .footer-logo {
  width: auto;
  height: 30px;
  margin: 0;
}
footer .footer-contents-wrapper .footer-link {
  color: #fff;
  text-decoration: none;
  margin-left: 30px;
}
footer .footer-contents-wrapper .footer-link:first-child {
  margin-left: 0;
}
footer .footer-contents-wrapper .footer-link:hover {
  color: #999;
}
@media (min-width: 992px) {
  footer .footer-contents-wrapper {
    padding: 15px 0;
  }
}

header .nav-contents {
  min-height: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 992px) {
  header .nav-contents {
    padding: 0;
  }
}
header .nav-contents .navbar-collapse {
  padding: 0;
}
header .nav-contents > a,
header .nav-contents > form {
  margin-right: 15px;
}
header .nav-contents form {
  flex: 2 1 auto;
}
header .nav-contents form div.input-group {
  display: flex;
  flex: 2 1 auto;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  min-height: 34px;
}
header .nav-contents form div.input-group input {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  z-index: 2;
}
header .nav-contents form div.input-group .input-group-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  display: inline-block;
  width: auto;
}
header .nav-contents a.logo {
  position: absolute;
  z-index: 1;
  top: 0;
  height: 50px;
  border-top: 8px solid rgba(0,0,0,0);
  border-bottom: 8px solid rgba(0,0,0,0);
  width: 125.71052631578947px;
  background: url("../img/logo-clonebe.png") no-repeat center center;
  background-size: contain;
}
header .nav-contents .dropdown .navbar-toggle {
  margin: 0;
  float: none;
}
@media (min-width: 768px) {
  header .nav-contents .dropdown .navbar-toggle {
    display: inline-block !important;
  }
}
header .nav-contents .dropdown .dropdown-menu {
  left: auto !important;
  right: 0;
  top: 40px;
}

#info-left > div dt,
#info-left > div dd {
  padding: 8px;
}
#info-left > div dt {
  font-size: 18px;
}
#info-left > div dd {
  font: size 14px;
  padding-left: 50px;
}

.navbar-toggle,
.btn-default,
.btn,
.navbar-default,
.navbar-default .navbar-toggle,
.input-group-addon,
.form-control {
  border-radius: 0px;
  background-image: none;
  color: #ef7d00;
  background-color: #fff;
  border-color: #e6e6e6;
  box-shadow: none;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #ef7d00;
}
.default-container-width {
  width: 100vw;
}
@media (min-width: 992px) {
  .default-container-width {
    width: 83.33333333%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 1200px) {
  .default-container-width {
    width: 66.66666666%;
  }
}
.fix-form-group {
  padding: 0 15px;
}
.button,
.btn {
  text-transform: capitalize;
}
.button span,
.btn span {
  text-transform: capitalize;
}
.ajax-load-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}


.wrapper-confirm-purchase {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 15px;
}
@media (min-width: 992px) {
  .wrapper-confirm-purchase {
    margin: 0;
  }
}
.wrapper-confirm-purchase .alert {
  margin: auto;
}
.wrapper-confirm-purchase > h3 {
  margin: 20px 0;
}
.wrapper-confirm-purchase #purchase-product-list {
  width: 100%;
  margin: 10px;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  background-color: rgba(255,255,255,0.85);
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item:last-of-type {
  border-bottom: 0;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.always-visible {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.always-visible span {
  padding: 10px;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.always-visible span.dropdown-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  padding: 0;
  background-color: rgba(243,151,51,0.5);
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.always-visible span.dropdown-icon div.arrow {
  margin: auto;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 8px solid #944d00;
  transition: all 500ms;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.always-visible span.dropdown-icon div.arrow.rotate-arrow {
  transform: rotate(90deg);
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.always-visible span.title {
  flex: 100 100 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bolder;
  background-color: #fff;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.always-visible span.price {
  background-color: rgba(109,211,147,0.5);
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.always-visible span.price:after {
  content: "€";
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.expanded {
  width: 100%;
  display: none;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.expanded figure {
  font-size: 0;
  background-color: rgba(243,151,51,0.5);
  text-align: center;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.expanded figure img {
  width: 100%;
  max-width: 400px;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.expanded ul li {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.expanded ul li:first-of-type {
  border-top: 1px solid #ddd;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.expanded ul li:last-of-type {
  border-bottom: 0;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.expanded ul li span:first-of-type {
  font-weight: bolder;
  color: #444;
}
.wrapper-confirm-purchase #purchase-product-list li.purchase-product-item div.expanded ul li span:last-of-type {
  font-weight: 200;
  color: #888;
}
.wrapper-confirm-purchase button {
  text-transform: uppercase;
}
.wrapper-confirm-purchase button:last-of-type {
  margin-bottom: 15px;
}

.wrapper-purchase-callback {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 15px;
}
@media (min-width: 992px) {
  .wrapper-purchase-callback {
    margin: 0;
  }
}
.wrapper-purchase-callback .alert {
  margin: auto;
}
.wrapper-purchase-callback > h3 {
  margin: 20px 0;
}
.wrapper-purchase-callback #purchase-product-list {
  width: 100%;
  margin: 10px;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  background-color: rgba(255,255,255,0.85);
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item:last-of-type {
  border-bottom: 0;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.always-visible {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.always-visible span {
  padding: 10px;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.always-visible span.dropdown-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  padding: 0;
  background-color: rgba(243,151,51,0.5);
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.always-visible span.dropdown-icon div.arrow {
  margin: auto;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 8px solid #944d00;
  transition: all 500ms;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.always-visible span.dropdown-icon div.arrow.rotate-arrow {
  transform: rotate(90deg);
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.always-visible span.title {
  flex: 100 100 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bolder;
  background-color: #fff;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.always-visible span.price {
  background-color: rgba(109,211,147,0.5);
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.always-visible span.price:after {
  content: "€";
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.expanded {
  width: 100%;
  display: none;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.expanded figure {
  font-size: 0;
  background-color: rgba(243,151,51,0.5);
  text-align: center;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.expanded figure img {
  width: 100%;
  max-width: 400px;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.expanded ul li {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.expanded ul li:first-of-type {
  border-top: 1px solid #ddd;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.expanded ul li:last-of-type {
  border-bottom: 0;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.expanded ul li span:first-of-type {
  font-weight: bolder;
  color: #444;
}
.wrapper-purchase-callback #purchase-product-list li.purchase-product-item div.expanded ul li span:last-of-type {
  font-weight: 200;
  color: #888;
}
.wrapper-purchase-callback button {
  text-transform: uppercase;
}
.wrapper-purchase-callback button:last-of-type {
  margin-bottom: 15px;
}

.wrapper-user-activation {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.wrapper-user-activation .alert {
  display: inline-block;
}
.wrapper-user-activation form {
  padding: 15px;
}
.wrapper-user-activation form .alert {
  display: block;
}
@media (min-width: 992px) {
  .wrapper-user-activation form {
    width: 992px;
  }
}

.wrapper-user-data {
  display: flex;
  width: 100%;
  background-color: rgba(239,125,0,0.6);
}
.wrapper-user-data form {
  margin: auto;
  padding: 15px;
}
@media (min-width: 992px) {
  .wrapper-user-data form {
    width: 992px;
  }
}

.wrapper-user-area-pricing {
  display: flex;
  width: 100%;
  background-color: rgba(239,125,0,0.6);
}
.wrapper-user-area-pricing .alert {
  margin: auto;
}
.wrapper-user-area-pricing > div.table-area {
  margin: 10px;
  width: calc(100% - 10px);
}
.wrapper-user-area-pricing > div.table-area .table-group-control {
  display: flex;
  justify-content: flex-end;
}
.wrapper-user-area-pricing > div.table-area .table-group-control #table-opts,
.wrapper-user-area-pricing > div.table-area .table-group-control #table-opts-button {
  margin-right: auto;
}
.wrapper-user-area-pricing > div.table-area .table-group-control button {
  margin-left: 10px;
}
.wrapper-user-area-pricing > div.table-area .table-group {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: row wrap;
  padding-top: 20px;
}
.wrapper-user-area-pricing > div.table-area .table-group .dataTables_scrollBody {
  height: 400px !important;
}
.wrapper-user-area-pricing > div.table-area .table-group .dataTables_scrollBody thead {
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  visibility: hidden !important;
  opacity: 0 !important;
}
.wrapper-user-area-pricing > div.table-area .table-group .dataTables_scrollBody {
  background-color: #f2f2f2;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  width: 290px;
  position: relative;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  transition: all 300ms;
  margin-bottom: 15px;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance:hover {
  box-shadow: 0 5px 11px 0 rgba(0,0,0,0.16), 0 4px 15px 0 rgba(0,0,0,0.12);
}
@media (min-width: 500px) {
  .wrapper-user-area-pricing > div.table-area .table-group .table-instance {
    margin-right: 50px;
    margin-bottom: 40px;
  }
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance:hover > .remove-material-btn {
  display: block;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance > .remove-material-btn {
  color: #fff;
  background-color: #000;
  border: 3px solid #fff;
  width: 60px;
  padding: 0;
  font-size: 30px;
  height: 60px;
  line-height: 60px;
  border-radius: 30px;
  position: absolute;
  top: -30px;
  left: -30px;
  transform: scale(0.45);
  z-index: 21302;
  display: none;
  transition: all 200ms;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance > .remove-material-btn:hover {
  text-shadow: 0 0 10px #fff;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance .table-controls {
  display: flex;
  justify-content: flex-end;
  color: #fff;
  background-color: #f39733;
  border-top: 0;
  padding: 5px;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance .table-controls button {
  background-color: rgba(0,0,0,0);
  color: #fff;
  text-transform: capitalize;
  border: 0;
  padding: 5px;
  margin: 0 10px 0 0;
  outline: 0;
  font-weight: bold;
  display: flex;
  font-family: zero-width;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance .table-controls button span {
  font-size: 20px;
  height: ;
  width: 20px;
  vertical-align: center;
  transition: all 200ms;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance .table-controls button span:hover {
  text-shadow: 0 0 10px #fff;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance .dataTables_scrollHead {
  background-color: #fff;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance .dataTables_scrollHead .dataTables_scrollHeadInner {
  position: relative;
  z-index: 1;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance .dataTables_scrollBody {
  border: 0;
  border-image-width: 0;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table.dataTable thead th,
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table.dataTable thead td,
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table.dataTable.no-footer {
  border: 0;
  border-image-width: 0;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table {
  background-color: #fff;
  table-layout: fixed;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table caption {
  padding: 10px 0;
  text-align: center;
  color: #fff;
  background-color: #f39733;
  border-bottom: 0;
  position: relative;
  font-size: 20px;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table caption + thead th:last-of-type {
  position: relative;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table caption + thead th:last-of-type:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 50px);
  height: 100%;
  background-color: #eec69b;
  z-index: -1;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table caption:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 50px);
  height: 100%;
  background-color: #f39733;
  z-index: -1;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table thead,
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table tfoot {
  background-color: #fce1c3;
  color: #575756;
  text-transform: capitalize;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table th,
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table td {
  text-align: center;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table th:first-of-type,
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table td:first-of-type {
  width: 10px;
  text-align: center;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table th:nth-of-type(odd) {
  background-color: rgba(188,98,0,0.2);
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table td:nth-of-type(odd) {
  background-color: rgba(255,247,239,0.5);
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table th:nth-of-type(even) {
  background-color: rgba(86,45,0,0.2);
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table td:nth-of-type(even) {
  background-color: rgba(255,223,188,0.5);
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table tbody tr:nth-of-type(odd) {
  background-color: #fff7ef;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table tbody tr:nth-of-type(even) {
  background-color: #ffdfbc;
}
.wrapper-user-area-pricing > div.table-area .table-group .table-instance table tbody td input:not([type=checkbox]) {
  margin: 0;
  padding: 0 0 0 10px;
  border: 0;
  outline: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0);
}

.wrapper-report {
  display: flex;
  width: 100%;
}
.wrapper-report ul {
  width: 100%;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.wrapper-report ul li {
  margin: 15px;
}
.wrapper-report ul li a {
  display: flex;
  width: calc(150px + 10vw);
  height: calc(150px + 10vw);
  padding: 0;
  border: 4px solid #ef7200;
  background-color: rgba(255,255,255,0.8);
  border-radius: 50%;
  position: relative;
  transition: all 600ms;
  text-decoration: none;
}
.wrapper-report ul li a:after {
  content: "\e026";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #ef7200;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Glyphicons Halflings';
  font-size: 140px;
  opacity: 0;
  transition: all 200ms;
  transform: scale(0);
  color: #fff;
}
.wrapper-report ul li a:hover,
.wrapper-report ul li a:active {
  background-color: rgba(255,255,255,0.8);
  border: 4px solid #fff;
  background-position: 0;
  transform: scale(1.1);
}
.wrapper-report ul li a:hover:after,
.wrapper-report ul li a:active:after {
  opacity: 1;
  transform: scale(1);
}
.wrapper-report ul li a div {
  white-space: normal;
  line-height: 3.6rem;
  font-size: 24px;
  font-weight: bold;
  width: 100%;
  padding: 30px;
  margin: auto;
  color: #ef7200;
  text-align: center;
  text-transform: capitalize;
}
@media (max-width: 700px) {
  .wrapper-report ul li a div {
    font-size: 18px;
  }
}
@media (max-width: 400px) {
  .wrapper-report ul li a div {
    font-size: 16px;
  }
}
.animation-ripple {
  animation: ripple 1000ms;
}
@-moz-keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  5% {
    box-shadow: 0 0 0 0 rgba(239,114,0,0.4);
  }
  100% {
    box-shadow: 0 0 0 100vw rgba(239,114,0,0);
  }
}
@-webkit-keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  5% {
    box-shadow: 0 0 0 0 rgba(239,114,0,0.4);
  }
  100% {
    box-shadow: 0 0 0 100vw rgba(239,114,0,0);
  }
}
@-o-keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  5% {
    box-shadow: 0 0 0 0 rgba(239,114,0,0.4);
  }
  100% {
    box-shadow: 0 0 0 100vw rgba(239,114,0,0);
  }
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  5% {
    box-shadow: 0 0 0 0 rgba(239,114,0,0.4);
  }
  100% {
    box-shadow: 0 0 0 100vw rgba(239,114,0,0);
  }
}

.wrapper-upload-product {
  display: flex;
  width: 100%;
  background-color: rgba(239,125,0,0.6);
}
.wrapper-upload-product .alert {
  margin: auto;
}
.wrapper-upload-product form {
  margin: auto;
  max-width: calc(100% - 30px) !important;
}
@media (min-width: 992px) {
  .wrapper-upload-product form {
    width: 992px;
  }
}
.wrapper-upload-product form fieldset {
  border-radius: 6px;
  padding: 15px;
  margin: 15px 0 40px;
  background-color: rgba(20,20,20,0.2);
}
.wrapper-upload-product form fieldset .progress-bar {
  height: 40px;
  width: 0;
  background-color: #00f;
  display: none;
}
.wrapper-upload-product form fieldset .drop-zone {
  display: flex;
  border: 6px dashed rgba(239,125,0,0.8);
  min-height: 25vh;
  background-color: rgba(255,255,255,0.6);
  display: none;
}
.wrapper-upload-product form fieldset .drop-zone .wrapper {
  margin: auto;
  color: rgba(239,125,0,0.8);
  text-shadow: 0 0 8px rgba(255,255,255,0.75);
  text-align: center;
  font-size: 3vh;
  font-weight: bold;
}
.wrapper-upload-product form fieldset .progress-bar {
  display: none;
}
.wrapper-upload-product form fieldset legend {
  width: inherit;
  font-size: 18px;
  background-color: rgba(239,125,0,0.8);
  box-shadow: 2px 2px 6px 0 rgba(139,25,0,0.6);
  color: #fff;
  border-radius: 4px;
  padding: 9px 15px;
}
.wrapper-upload-product form fieldset ul li {
  padding: 10px;
}
.wrapper-upload-product form fieldset ul li:hover {
  background-color: #eee;
}

.wrapper-welcome {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper-basic-error {
  width: 100%;
  display: flex;
}
.wrapper-basic-error > div {
  margin: auto;
}
