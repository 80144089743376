#info-left > div
    dt,
    dd
        padding 8px

    dt
        font-size 18px

    dd
        font size 14px
        padding-left 50px
